<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
              {{ $auth.activeCompany.companyName }}
            </div>
            <h2 class="page-title">Leads</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl" v-if="loaded">
        <div class="row mb-3" v-if="!$auth.isFeatured">
          <div class="col">
            <upgrade-to-access>
              <p>
                Upgrade to a featured listing to start generating leads from
                your products and whitepapers.
              </p>
            </upgrade-to-access>
          </div>
        </div>
        <div class="row row-deck row-cards" v-else>
          <div class="col col-lg-2">
            <form action="" method="get">
              <date-filters
                :all-filters="date_filters"
                :bus="this.filter.selected_datefilter"
                v-on:update:selected="updateSelectedDateFilter"
              ></date-filters>

              <div v-if="custom" v-bind="custom">
                <date-select
                  :label="`Start Date`"
                  v-on:update:selected="updateSelectedStartDate"
                ></date-select>

                <date-select
                  :label="`End Date`"
                  v-on:update:selected="updateSelectedEndDate"
                ></date-select>
              </div>

              <div v-if="$auth.ignoreDemo.isFeatured">
                <div class="subheader mb-2">Export</div>
                <div class="mb-3">
                  <button
                    class="btn btn-primary w-100"
                    @click.prevent="generateCSV()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-table-export"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M11.5 20h-5.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v7.5m-16 -3.5h16m-10 -6v16m4 -1h7m-3 -3l3 3l-3 3"
                      ></path>
                    </svg>
                    Download as CSV
                  </button>
                </div>
              </div>
              <div v-if="reload">
                <div class="spinner-border mt-2" role="status"></div>
              </div>
            </form>
          </div>
          <div class="col-lg-10">
            <div class="container-xl" v-if="filteredleadDTOs.length > 0">
              <div class="row">
                <div class="col">
                  <div class="d-flex justify-content-end">
                    <div class="subheader mb-2" id="topOftable">
                      Showing {{ filteredleadDTOs.length }} out of
                      {{ leadDTOs.length }} Leads
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="table-responsive">
                      <table
                        class="table table-vcenter card-table table-striped"
                      >
                        <thead>
                          <tr>
                            <th>
                              <a href="#" @click="sort(`sentDate`)">
                                Lead Date
                              </a>
                            </th>
                            <th class="text-left text-nowrap">
                              <a href="#" @click="sort(`leadAttribution`)">
                                Lead Attribution
                              </a>
                            </th>
                            <th class="text-left text-nowrap">
                              <a href="#" @click="sort(`reportLeadType`)">
                                Lead Type
                              </a>
                            </th>
                            <th class="text-left">
                              <a href="#" @click="sort(`leadMagnet`)">
                                Lead Magnet/Asset
                              </a>
                            </th>
                            <th class="text-left">
                              <a href="#" @click="sort(`siteName`)">
                                Directory Site
                              </a>
                            </th>
                            <th class="text-left text-nowrap">
                              <a href="#" @click="sort(`leadFirstName`)"
                                >First Name</a
                              >
                            </th>
                            <th class="text-left text-nowrap">
                              <a href="#" @click="sort(`leadLastName`)"
                                >Last Name</a
                              >
                            </th>
                            <th class="text-left">
                              <a href="#" @click="sort(`leadTitle`)">Title</a>
                            </th>
                            <th class="text-left">
                              <a href="#" @click="sort(`leadCompanyName`)"
                                >Company</a
                              >
                            </th>
                            <th class="text-left text-nowrap">
                              <a href="#" @click="sort(`leadCompanyName`)"
                                >Email Address</a
                              >
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="leadDTO in displayleadDTOs"
                            v-bind:key="leadDTO.rowid"
                          >
                            <td>
                              <div class="text-left text-nowrap">
                                {{ leadDTO.sentDate }}
                              </div>
                            </td>
                            <td>
                              <div class="text-left text-nowrap">
                                {{ leadDTO.leadAttribution }}
                              </div>
                            </td>
                            <td>
                              <div class="text-left text-nowrap">
                                {{ leadDTO.reportLeadType }}
                              </div>
                            </td>
                            <td>
                              <div class="text-left text-nowrap">
                                {{ leadDTO.leadMagnet }}
                              </div>
                            </td>
                            <td>
                              <div class="text-left text-nowrap">
                                {{ leadDTO.siteName }}
                              </div>
                            </td>
                            <td>
                              <div class="text-left text-nowrap">
                                {{ leadDTO.leadFirstName }}
                              </div>
                            </td>
                            <td>
                              <div class="text-left text-nowrap">
                                {{ leadDTO.leadLastName }}
                              </div>
                            </td>
                            <td>
                              <div class="text-left">
                                {{ leadDTO.leadTitle }}
                              </div>
                            </td>
                            <td>
                              <div class="text-left">
                                {{ leadDTO.leadCompanyName }}
                              </div>
                            </td>
                            <td>
                              <div class="text-left">
                                {{ leadDTO.leadEmailAddress }}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-xl" v-else>
              <span v-if="selected_datefilter != ''">
                No leads exists for the dates selected.
              </span>
              <span v-else>
                Your company does not have any Featured Placements with leads.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="container-xl" v-else>
        <div class="row">
          <div class="col">
            <h3>Preparing leads...</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.table .text-right {
  text-align: right;
}
.table .text-left {
  text-align: left;
}
.table .text-center {
  text-align: center;
}
.table-responsive {
  margin-bottom: 0px;
}
</style>

<script>
import LeadService from "../services/LeadService";
import CSVService from "../services/CSVService";
import TrackingService from "../services/TrackingService";
import DateFilters from "../components/Reports/DateFilters.vue";
import DateSelect from "../components/Reports/DateSelect.vue";
import UpgradeToAccess from "../components/Reports/UpgradeToAccess.vue";

import { format, isBefore, parseISO, add } from "date-fns"; //parseISO format isBefore

export default {
  name: "Leads",
  components: {
    DateFilters,
    DateSelect,
    UpgradeToAccess,
  },
  data: () => {
    return {
      loaded: false,
      reload: false,
      sortdir: "desc",
      sortcol: "sentDate",
      leadDTOs: [],
      date_filters: [
        "Year To Date",
        "Last 12 Months",
        "Month To Date",
        "Today",
        "Custom",
      ],
      custom: false,
      rows: 0,
      filter: {
        selected_categories: [],
        selected_sites: [],
        selected_datefilter: "",
        selected_startdate: "",
        selected_enddate: "",
      },
    };
  },
  methods: {
    generateCSV() {
      TrackingService.track("download lead export", {
        detail: `from ${this.filter.selected_startdate} to ${this.filter.selected_enddate}`,
      });
      let fields = [
        {
          id: "LeadDate",
          getter: (r) => r["sentDate"],
        },
        {
          id: "LeadAttribution",
          getter: (r) => r["leadAttribution"],
        },
        {
          id: "LeadType",
          getter: (r) => r["reportLeadType"],
        },
        {
          id: "LeadAsset",
          getter: (r) => r["leadMagnet"],
        },
        {
          id: "DirectorySiteName",
          getter: (r) => r["siteName"],
        },
        {
          id: "FirstName",
          getter: (r) => r["leadFirstName"],
        },
        {
          id: "LastName",
          getter: (r) => r["leadLastName"],
        },
        {
          id: "Title",
          getter: (r) => r["leadTitle"],
        },
        {
          id: "EmailAddress",
          getter: (r) => r["leadEmailAddress"],
        },
        {
          id: "Company",
          getter: (r) => r["leadCompanyName"],
        },
        {
          id: "CompanySize",
          getter: (r) => r["leadNumberOfEmployees"],
        },
        {
          id: "Industry",
          getter: (r) => r["leadIndustry"],
        },
        {
          id: "PhoneNumber",
          getter: (r) => r["leadPhone"],
        },
        {
          id: "Address",
          getter: (r) => r["leadAddress"],
        },
        {
          id: "Address2",
          getter: (r) => r["leadAddress2"],
        },
        {
          id: "City",
          getter: (r) => r["leadCity"],
        },
        {
          id: "State",
          getter: (r) => r["leadState"],
        },
        {
          id: "PostalCode",
          getter: (r) => r["leadPostalCode"],
        },
        {
          id: "Country",
          getter: (r) => r["leadCountry"],
        },
        {
          id: "MessageSubject",
          getter: (r) => r["leadEmailSubject"],
        },
        {
          id: "Message",
          getter: (r) => r["leadEmailMessage"],
        },
      ];
      let csv = CSVService.serialize({
        fields: fields,
        records: this.filteredleadDTOs,
      });
      let download = document.createElement("a");
      let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      let url = URL.createObjectURL(blob);
      download.href = url;
      download.setAttribute("download", "lead_export.csv");
      download.click();
    },
    updateSelectedSites(selected) {
      this.$set(this.filter, "selected_sites", selected);
    },
    updateSelectedDateFilter(selected) {
      TrackingService.track("change lead dates");

      // set the selected datefilter
      this.$set(this.filter, "selected_datefilter", selected);

      // set custom flag
      this.custom = selected == "Custom" ? true : false;

      // set appropriate dates for filter
      switch (selected) {
        case "":
          this.$set(this.filter, "selected_startdate", "");
          this.$set(this.filter, "selected_enddate", "");
          break;
        case "Year To Date":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(new Date(new Date().getFullYear(), 0, 1))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        case "Last 12 Months":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(add(new Date(), { years: -1 }))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        case "Month To Date":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(add(new Date(), { months: -1 }))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        case "Today":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(new Date())
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        default:
          break;
      }

      // set appropriate dates for filter
      switch (selected[0]) {
        case "":
          this.$set(this.filter, "selected_startdate", "");
          this.$set(this.filter, "selected_enddate", "");
          break;
        case "Year To Date":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(new Date(new Date().getFullYear(), 0, 1))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        case "Month To Date":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            )
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        case "Today":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(new Date())
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        default:
          break;
      }

      // load ad data for company
      this.getLeadData(true);
    },
    updateSelectedStartDate(selected) {
      this.$set(this.filter, "selected_startdate", selected);
      if (this.filter.selected_enddate != "") {
        this.getLeadData(true);
      }
    },
    updateSelectedEndDate(selected) {
      this.$set(this.filter, "selected_enddate", selected);
      this.getLeadData(true);
    },
    getFormattedDate(thedate) {
      let year = new Date(thedate).getFullYear();
      let month = (1 + new Date(thedate).getMonth())
        .toString()
        .padStart(2, "0");
      let day = new Date(thedate).getDate().toString().padStart(2, "0");
      return month + "/" + day + "/" + year;
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.sortcol) {
        this.sortdir = this.sortdir === "asc" ? "desc" : "asc";
      }
      this.sortcol = s;
    },
    async getLeadData(isreload) {
      let company = this.$auth.activeCompany;

      let leadFilter = {
        Company: { Companyid: company.companyId },
        StartDate: this.filter.selected_startdate,
        EndDate: this.filter.selected_enddate,
      };

      // mark as reload or not
      this.reload = isreload;

      // make service call
      let leadDTOs = await LeadService.getLeads(leadFilter);

      leadDTOs.forEach((n) => {
        // incrment rows
        this.rows++;

        n.leadSource = n.leadSource ? n.leadSource : "";

        // format sentdate
        n.sentDate = format(parseISO(n.sendDate), "MM/dd/yyyy hh:mm aaa");

        // build rowid
        n.rowid = this.rows;
      });

      // sort by sitename
      this.leadDTOs = leadDTOs;

      // mark as loaded
      this.loaded = true;

      // mark as reloaded
      this.reload = false;
    },
  },
  computed: {
    filteredleadDTOs() {
      return this.leadDTOs
        .filter((n) => {
          if (this.filter.selected_sites.length == 0) return true;
          return this.filter.selected_sites.indexOf(n.siteName) >= 0;
        })
        .sort((a, b) => {
          let modifier = 1;
          if (this.sortdir === "desc") modifier = -1;

          if (this.sortcol != "sentDate") {
            if (a[this.sortcol] < b[this.sortcol]) return -1 * modifier;
            if (a[this.sortcol] > b[this.sortcol]) return 1 * modifier;
          } else {
            if (isBefore(new Date(a[this.sortcol]), new Date(b[this.sortcol])))
              return -1 * modifier;
            if (!isBefore(new Date(a[this.sortcol]), new Date(b[this.sortcol])))
              return 1 * modifier;
          }
        });
    },
    displayleadDTOs() {
      return this.filteredleadDTOs;
    },
  },
  async mounted() {
    // load lead data for company
    this.getLeadData(false);

    TrackingService.track("viewed leads page");
  },
};
</script>
